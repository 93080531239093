import React from "react";
import { Placeholder } from "gatsby-plugin-image";
import Matrix from "../matrix";

const CardAlt = ({ title, summary, body, image, id }) => {

  const finalImage = image ? <figure><Placeholder className="h-48 w-64" /></figure> : ""; 
  const finalMatrix = body ? <Matrix blocks={body} /> : "";

  return (
    <div className="box-hr" style={{marginTop: "20px"}} id={id}>
      <div className="box__inner">
        {finalImage}
        <div className="box__body" style={{paddingBottom: "0"}}>
          <p className="box__title h5">{title}</p>
          <div className="box__entry truncate-overflow" dangerouslySetInnerHTML={{ __html: summary }}></div>
          {finalMatrix}
          <hr />
        </div>
      </div>
    </div>
  )
}

export default CardAlt;