import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import SectionNav from "../components/newComponents/sectionNav";
import SectionHead from "../components/newComponents/sectionHead";
import CardAlt from "../components/newComponents/cardAlt";
import Card from "../components/newComponents/card";

export const query = graphql`
  query WhatWeDoAreasOfExpertiseEntryQuery($slug: String!) {
    entry: craftWhatWeDoWhatWeDoEntry(slug: { eq: $slug }) {
      id
      remoteId
      title
      summary
      relatedAreasOfExpertiseFocusAreas {
        ... on Craft_whatWeDo_whatWeDo_Entry {
          displayTitle
          summary
          uri
          featureImage {
            ... AssetFragment
          }
        }
      }
      relatedAreasOfExpertise {
        ... on Craft_areaOfExpertise_areaOfExpertise_Entry {
          slug
          title
          uri
          summary
          bodyContent {
            ...BodyContentFragment
          }
        }
      }
      relatedProjects {
        ... on Craft_projects_projects_Entry {
          id
        }
      }
      relatedFocusArea {
        ... on Craft_whatWeDo_whatWeDo_Entry {
          id
        }
      }
      relatedTeamMembers {
        ... on Craft_staff_staff_Entry {
          slug
        }
      }
    }

    relatedFocusAreaProjects: allCraftProjectsProjectsEntry(filter: {relatedFocusArea: {elemMatch: {slug: {eq: $slug}}}} sort: {order: DESC, fields: postDate}) {
      nodes {
        id
      }
    }

    relatedAreaOfExpertiseProjects: allCraftProjectsProjectsEntry(filter: {relatedAreaOfExpertise: {elemMatch: {slug: {eq: $slug}}}} sort: {order: DESC, fields: postDate}) {
      nodes {
        id
      }
    }
  }
`;

const WhatWeDoAreasOfExpertiseEntry = ({ data, pageContext }) => (
  <Layout pageContext={pageContext}>
    <Seo title={data.entry.title} />
    <div className="main">
      <SectionNav data={data} base={`/what-we-do/${pageContext.slug}`} currentUri={`/what-we-do/${pageContext.slug}/areas-of-expertise`}/>
      <section className="section-overview" style={{paddingBottom: 0}}>
          <div className="shell">
          <SectionHead title={data.entry.title} subtitle={"Areas of Expertise"} />
            </div>
        </section>
      <section className="section-boxes-hr js-section-boxes-hr" style={{paddingTop: 0}}>
        <div className="shell">
          
          {data.entry.relatedAreasOfExpertiseFocusAreas.length > 0 &&
            <div className="boxes swiper-container js-load-more js-slider-boxes-projects">
              <ul>
                {data.entry.relatedAreasOfExpertiseFocusAreas.map((item, i) => (
                  <Card
                    key={`what-we-do-entry-area-of-expertise-focus-areas-${i}`} 
                    image={item.featureImage[0]} 
                    title={item.displayTitle} 
                    subtitle={item.summary} 
                    href={`/${item.uri}`} 
                    color={2}
                  />
                ))}
              </ul>
              <div className="swiper-pagination boxes__pagination" />
            </div>
          }
          {data.entry.relatedAreasOfExpertise.length > 0 &&
            <div>
              {data.entry.relatedAreasOfExpertise.map((item, i) => (
                <CardAlt id={`id-${item.slug}`} key={`what-we-do-areas-of-expertise-entry-${i}`} title={item.title} body={item.bodyContent} />
              ))}
            </div>
          }
          {data.entry.relatedAreasOfExpertiseFocusAreas.length === 0 && data.entry.relatedAreasOfExpertise.length === 0 &&
            <div>No content found.</div>
          }
        </div>
      </section>
    </div>
  </Layout>
);

export default WhatWeDoAreasOfExpertiseEntry;